import React from 'react'

const Techskills = () => {
  return (
    <>
        <div className='container pt-4'>
            <h2 className='text-center'>SKILLS</h2>
            <article className='skills-section'>
                <h3 className='mt-4 mb-4 fs-5'>TECHNICAL SKILLS?</h3>
                <span className='text-black-50'>As a web developer, I learned many technologies to help me bring my ideas to life.</span>
            </article>
            
        </div>
    </>
  )
}

export default Techskills