import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCopyFunction } from '../EmailContext';
import { useCopynumber } from '../EmailContext';
import 'swiper/css';
const Contactme = () => {
    const handlecopy = useCopyFunction();
    const handlecopynumber = useCopynumber();
  return (
    <div className='container mt-5 contactme-section'>
        <h2 className='text-center mb-4'>CONTACT ME</h2>
        <span className='text-black-50'>
            Feel free to contact me
        </span>
        <div className='mt-5'>
        <Swiper
        spaceBetween={-30}
        breakpoints={{
            576: {
                width: 576,
                slidesPerView: 1,
                },
            600: {
                width: 600,
                slidesPerView: 2,
                },
            768: {
                width: 768,
                slidesPerView: 3,
                },
            992: {
            width: 992,
            slidesPerView: 3,
            },
            1200: {
            width: 1200,
            slidesPerView: 3,
            },
        }}
        >
            <SwiperSlide>
                <div className='border p-3 content-swiper-compenent bg-light'>
                    <span>
                        <i class="fa-solid fa-envelope me-2 border p-1"></i>
                        EMAIL
                    </span>
                    <ul className='list-unstyled ps-3 pt-3 text-black-50'>
                        <li>I USUALLY, REPLY IN LESS THAN 5 HOURS</li>
                        <li className='mt-3'>walidbouhanout@gmail.com</li>
                        <button onClick={handlecopy}>Copy email</button>
                    </ul>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='border p-3 content-swiper-compenent bg-light'>
                    <span>
                        <i class="fa-brands fa-linkedin-in me-2 border p-1"></i>
                        LINKEDIN
                    </span>
                    <ul className='list-unstyled ps-3 pt-3 text-black-50'>
                        <li>I USUALLY, REPLY IN LESS THAN 2 HOURS</li>
                        <li className='mt-3'>/Walid Bouhanout</li>
                        <button> <a href='https://linkedin.com\in\walidbouhanout' className='text-decoration-none text-white'>My linkedin</a></button>
                    </ul>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='border p-3 content-swiper-compenent bg-light'>
                    <span>
                        <i class="fa-solid fa-phone me-2 border p-1"></i>
                        PHONE NUMBER
                    </span>
                    <ul className='list-unstyled ps-3 pt-3 text-black-50'>
                        <li>AVAILABLE, ALMOST ALL THE TIME</li>
                        <li className='mt-3'>+212 650780399</li>
                        <input type='text' value='0650780399' readOnly className='d-none' id='phonenumber'/>
                        <button onClick={handlecopynumber}>Copy my number</button>
                    </ul>
                </div>
            </SwiperSlide>
        </Swiper>
        </div>
    </div>
  )
}

export default Contactme