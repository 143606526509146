import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
const SkillsSwiper = () => {
  return (
    <div className='container mt-5'>
        <Swiper
        spaceBetween={-30}
        breakpoints={{
            576: {
                width: 576,
                slidesPerView: 1,
                },
            600: {
                width: 600,
                slidesPerView: 2,
                },
            768: {
                width: 768,
                slidesPerView: 3,
                },
            992: {
            width: 992,
            slidesPerView: 4,
            },
            1200: {
            width: 1200,
            slidesPerView: 4,
            },
        }}
        >
            <SwiperSlide>
                <div className='border p-3 skills-swiper-compenent bg-light'>
                    <span>
                        <i class="fa-brands fa-sketch me-2 border p-1"></i>
                        FRONT END
                    </span>
                    <ul className='list-unstyled ps-3 pt-3 text-black-50'>
                        <li>HTML5</li>
                        <li>CSS3</li>
                        <li>JAVA SCRIPT</li>
                        <li>REACT</li>
                        <li>JQUERY</li>
                        <li>BOOTSTRAP</li>
                    </ul>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='border p-3 skills-swiper-compenent bg-light'>
                    <span>
                        <i class="fa-solid fa-server me-2 border p-1"></i>
                        BACK END
                    </span>
                    <ul className='list-unstyled ps-3 pt-3 text-black-50'>
                        <li>PHP</li>
                        <li>JEE</li>
                    </ul>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='border p-3 skills-swiper-compenent bg-light'>
                    <span>
                        <i class="fa-solid fa-database me-2 border p-1"></i>
                        DATA BASE
                    </span>
                    <ul className='list-unstyled ps-3 pt-3 text-black-50'>
                        <li>SQL</li>
                        <li>PL/SQL</li>
                    </ul>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='border p-3 skills-swiper-compenent bg-light'>
                    <span>
                        <i class="fa-solid fa-code-branch me-2 border p-1"></i>
                        VERSION CONTROL
                    </span>
                    <ul className='list-unstyled ps-3 pt-3 text-black-50'>
                        <li>GIT</li>
                        <li>GITHUB</li>
                    </ul>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
  )
}

export default SkillsSwiper