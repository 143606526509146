import React from 'react'
import { useCopyText } from '../EmailContext'
const Alert = () => {
  const copyText = useCopyText();
  console.log(copyText);
  return (
    <div className={!copyText?"alert alert-primary position-fixed alert-card-email d-none":"alert alert-primary position-fixed alert-card-email"} role="alert">
        COPIED
    </div>
  )
}

export default Alert