import React from 'react'
import earth from "../assets/earth.png"
const Home = () => {
  return (
    <>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-4 col-12'>
                    <h1 className='text-white'>Hello, I'm Walid Bouhanout Web Developer</h1>
                    <span className='home-text-under-h1 d-block mt-4'>
                    Web developer with experience in development and problem-solving in many personal projects.
                    </span>
                    <button className='mt-4 home-learn-more border-0 text-white p-2 '>
                        <a href='#aboutme' className='text-white text-decoration-none '>More</a>
                    </button>
                </div>
                <div className='col-8 d-none d-lg-inline'>
                    <img className='img-fluid w-100' src={earth}/>
                </div>
            </div>
        </div>
    </>
  )
}

export default Home