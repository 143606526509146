import React from 'react'
import logo from "../assets/Logo.svg"
import { useCopyFunction } from '../EmailContext'
function Header() {
    const handlecopy = useCopyFunction();
  return (
    <>
       <header className='container pt-4 pb-4'>
            <div className='row align-items-center'>
                <div className='col-lg-4 col-6 logo text-white'>
                    <img className='img-fluid my-logo' src={logo}/>
                </div>
                <nav className='col-4 d-lg-flex d-none flex-row justify-content-between desktop-navigation'>
                    <li><a href='#home'>Home</a></li>
                    <li><a href='#aboutme'>About me</a></li>
                    <li><a href='#skills'>Skills</a></li>
                    <li><a href='#projects'>Projects</a></li>
                </nav>
                <nav className='col-4 d-lg-flex d-none flex-row justify-content-end side-bar-social-media'>
                                <li><a href='#'><i className="fa-brands fa-google" onClick={handlecopy}></i></a></li>
                                <input type="text" className='d-none' value="walidbouhanout@gmail.com" readOnly id="email-input"></input>
                                <li><a href='https://linkedin.com\in\walidbouhanout' target="blank"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                <li><a href='https://github.com/asyncwalid' target="blank"><i className="fa-brands fa-github"></i></a></li>
                </nav>
                {/* off canvas */}
                <button className="col-6 d-lg-none d-flex justify-content-end bg-transparent border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                    <i className="fa-solid fa-bars text-white"></i>
                </button>

                <div className="offcanvas offcanvas-end side-bar-style w-75" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title logo text-white" id="offcanvasWithBothOptionsLabel">
                        <img className='img-fluid my-logo' src={logo}/>
                        </h5>
                        <button type="button" className="bg-transparent border-0 side-bar-close pt-1 pe-2 ps-2" data-bs-dismiss="offcanvas" aria-label="Close">
                            <i className="fa-solid fa-xmark text-white fs-4"></i>
                        </button>
                    </div>
                    <div className="offcanvas-body ">
                        <span className='side-bar-indice text-uppercase'>Navigation</span>
                        <nav className='d-flex flex-column justify-content-between side-bar-navigation mt-3'>
                            <li><a href='#home'><i className="fa-solid fa-house"></i> Home</a></li>
                            <li><a href='#aboutme'><i className="fa-solid fa-user"></i> About me</a></li>
                            <li><a href='#skills'><i className="fa-solid fa-laptop"></i> Skills</a></li>
                            <li><a href='#projects'><i className="fa-solid fa-folder-open"></i> Projects</a></li>
                        </nav>
                        <div className='d-flex flex-column  mt-5'>
                            <span className='side-bar-indice text-uppercase'>Contact</span>
                            <nav className='d-flex flex-row side-bar-social-media mt-3'>
                                <li><a href='#'><i className="fa-brands fa-google" onClick={handlecopy}></i></a></li>
                                <input type="text" className='d-none' value="walidbouhanout@gmail.com" readOnly id="email-input"></input>
                                <li><a href='https://linkedin.com\in\walidbouhanout' target="blank"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                <li><a href='https://github.com/asyncwalid'><i className="fa-brands fa-github"></i></a></li>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header> 
    </>
  )
}

export default Header