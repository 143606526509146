import React from 'react'
import { Emailcopycontext } from './EmailContext';
import Header from './component/Header'
import Home from './component/Home';
import Partner from './component/Partner';
import Alert from './component/Alert';
import Aboutme from './component/Aboutme';
import Techskills from './component/Techskills';
import SkillsSwiper from './component/SkillsSwiper';
import Softskills from './component/Softskills';
import Projects from './component/Projects';
import Contactme from './component/Contactme';
import Thankssection from './component/Thankssection';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css" 
const App = () => {
  return (
    <Emailcopycontext>
      <section className='home-page d-flex flex-column justify-content-between position-relative' id='home'>
        <Header/>
        <Home/>
        <Partner/>
        <Alert/>
      </section>
      <section id='aboutme'>
        <Aboutme/>
      </section>
      <section id='skills'>
        <Techskills/>
        <SkillsSwiper/>
        <Softskills/>
      </section>
      <article id='projects'>
        <Projects/>
      </article>
      <footer>
        <Contactme/>
        <Thankssection/>
      </footer>
    </Emailcopycontext>
  )
}

export default App