import React from 'react'
import imgfsection from "../assets/4453416.svg"
const Aboutme = () => {
  return (
    <>
        <div className='container pt-4 d-flex justify-content-between flex-column h-100'>
            <h2 className='text-center mb-5'>ABOUT ME</h2>
            <article className='row  align-items-center about-me'>
                <div className='col-lg-6 col-12 text-lg-start text-center mb-lg-0 mb-5'>
                    <h3 className='mb-4'>WEB DEVELOPER FROM CASABLANCA</h3>
                    <span>
                        Passionate about development, especially in the web field. My curiosity about the internet and how it keeps the world connected takes me to learn new technologies and enjoy what I'm doing.                    </span>
                </div>
                <div className='col-lg-6 col-12 d-flex justify-content-center justify-content-md-center'>
                    <img className='' src={imgfsection}/>
                </div>
            </article>
            <nav className='d-flex flex-row justify-content-lg-start justify-content-center side-bar-social-media pb-4 pt-4'>
                <li><a href='https://linkedin.com\in\walidbouhanout' target="blank"><i className="fa-brands fa-linkedin-in fs-4"></i></a></li>
                <li><a href='https://github.com/asyncwalid' target="blank"><i className="fa-brands fa-github fs-4"></i></a></li>
            </nav>
        </div>
    </>
  )
}

export default Aboutme