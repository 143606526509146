import { useState ,createContext, useContext } from "react"
    const CopyContext = createContext();
    const Copyfunction = createContext();
    const Copynumber = createContext();
export function useCopyText(){
    return useContext(CopyContext)
}
export function useCopyFunction(){
    return useContext(Copyfunction)
}
export function useCopynumber(){
    return useContext(Copynumber)
}
export function Emailcopycontext({children}){
    const [copied,setcopied] = useState(false);
    const handlecopy =()=> {
        let copyText = document.getElementById("email-input");

        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        navigator.clipboard.writeText(copyText.value);

        setcopied(prevcopied => !prevcopied)
        setTimeout(
            ()=>setcopied(prevcopied => !prevcopied),
            1500
        )
        // Working solution
        // document.querySelector(".alert-card-email").classList.toggle("d-none")
        // setTimeout(()=>{
        //     document.querySelector(".alert-card-email").classList.toggle("d-none")
        // },1500)
    }
    const copyunumber = () => {
        let copynumber = document.getElementById("phonenumber");
        copynumber.select();
        copynumber.setSelectionRange(0, 99999); /* For mobile devices */

        navigator.clipboard.writeText(copynumber.value);

        setcopied(prevcopied => !prevcopied)
        setTimeout(
            ()=>setcopied(prevcopied => !prevcopied),
            1500
        )
    }
    return (
        <CopyContext.Provider value={copied}>
            <Copyfunction.Provider value={handlecopy}>
                <Copynumber.Provider value={copyunumber}>
                    {children}
                </Copynumber.Provider>
            </Copyfunction.Provider>
        </CopyContext.Provider>
    )
}