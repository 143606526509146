import React from 'react'

const Thankssection = () => {
  return (
    <div className='container mt-5 mb-3 text-center'>
        <h2 className='mb-3'>THANK YOU</h2>
        <span>Thank you for your interest in my application, I hope you enjoyed my work.</span>
        <nav className='d-flex flex-row justify-content-center side-bar-social-media pb-4 pt-4'>
            <li><a href='https://linkedin.com\in\walidbouhanout' target="blank"><i className="fa-brands fa-linkedin-in fs-4"></i></a></li>
            <li><a href='https://github.com/asyncwalid' target="blank"><i className="fa-brands fa-github fs-4"></i></a></li>
        </nav>
    </div>
  )
}

export default Thankssection