import React from 'react'
import ecommerce from '../assets/ecom.png'
import webpayment from '../assets/eth.png'
const Projects = () => {
  return (
    <div className='container mt-5 pt-3 mb-5 projects-page h-100'>
        <h2 className='mb-4 text-center'>Projects</h2>
        <span>E-COMMERCE PLATFORM </span>
        <div className='row mt-4 mb-4 align-items-center'>
          <div className='col-lg-6 col-12'>
            <img className='img-fluid' src={ecommerce}/>
          </div>
          <div className='col-lg-6 col-12 mt-4'>
            <ul>
              <li>I created an e-commerce platform with which you can list items.</li>
              <li>
                This platform supports two types of users, the first ones who are
                interested in buying items. The second type of users, who are
                interested in buying and selling at the same time, can switch
                from buyer account to seller account.
              </li>
              <li>The seller account has a dashboard with which he can process
                  orders and profits and also can either add or modify items in
                  different categories and edit their details and profile.
              </li>
              <li>
                  Each vendor has a level for a good user experience.
              </li>
              <li>
                This project was created using HTML5, CSS3, Java script, Php,
                Sql.
              </li>
            </ul>
          </div>
        </div>
        <span className=''>WEB3 PAYMENT</span>
        <div className='row d-flex flex-lg-row-reverse flex-row mt-4 align-items-center'>
          <div className='col-lg-6 col-12'>
            <img className='img-fluid' src={webpayment}/>
          </div>
          <div className='col-lg-6 col-12 mt-4'>
            <ul>
              <li>I created this site for metamask users who want to send
                ethereum to other addresses.
              </li>
              <li>
                When the user logs in for the first time with their wallet, they will
                be asked to define a username and then have access to the
                service.
              </li>
              <li>If the transaction fails or succeeds, he can see the result on the
                screen
              </li>
              <li>
              This project was created using Html5, Css3, Java script, Solidity,
              Web3.js, Truffle.js.
              </li>
            </ul>
          </div>
        </div>
    </div>
  )
}
export default Projects