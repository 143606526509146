import React from 'react'

const Partner = () => {
  return (
    <div className='container mb-4'>
        <div className='row language-row'>
            <div className='col-md-2 col-4'>
                <i className="fa-brands fa-js"></i>
            </div>
            <div className='col-md-2 col-4'>
                <i className="fa-brands fa-php"></i>
            </div>
            <div className='col-md-2 col-4'>
                <i className="fa-solid fa-database"></i>
            </div>
            <div className='col-md-2 col-4'>
                <i className="fa-brands fa-react"></i>
            </div>
            <div className='col-md-2 col-4'>
                <i className="fa-brands fa-bootstrap"></i>
            </div>
            <div className='col-md-2 col-4'>
                <i className="fa-brands fa-git"></i>
            </div>
        </div>
    </div>
  )
}

export default Partner