import React from 'react'

const Softskills = () => {
  return (
    <div class="container accordion mt-3" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <button class="accordion-button bg-light soft-skills-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                SOFT SKILLS
            </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div class="accordion-body">
                <ul className=' text-black-50 soft-skills-section'>
                    <li>Problem solving and self-learning.</li>
                    <li>Team spirit and autonomous work.</li>
                    <li>Ability to follow instructions and achieve quality results.</li>
                </ul>
            </div>
            </div>
        </div>
    </div>

  )
}

export default Softskills